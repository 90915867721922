import React, { useState } from "react"
// import useMyContext from "../../store/useMyContext"
import { Icon, Tooltip } from "antd"
import moment from "moment-timezone"

// import { EquipmentSelect , SpecialtySelect } from "./Forms"
import { CheckBox, EmployeeSelect, ShortDayFields, StartTimeField, WeekdayPicker, AssignmentSelect, PayTypeSelect } from "../Forms"
import { DurationSelect, BusinessUnitSelect, SubLedgerSelect, InlineEdit } from "../Forms"
import DateSelector from "./DateSelector"

import { REQ, store, _ } from "../../store/psrs"

const RosterSlot = props => {
  const { mutate, url } = props
  // const context = useMyContext()
  const Slot = props.slot
  const Template = props.template

  const [loading, setLoading] = useState(props.loading || false)

  const tStart = Slot.trainStartDate ? moment(Slot.trainStartDate) : moment().hour(9).minute(0).second(0)
  const tFormat = 'YYYY-MM-DD HH:mm'

  const backHalfTagId  = _.find(store.get("Tags"), { name: "Patrol Training Back Half" }).id
  const frontHalfTagId = _.find(store.get("Tags"), { name: "Patrol Training Front Half" }).id

  const hasTrainingGroup = Template.tags.some(tag => tag.id === backHalfTagId || tag.id === frontHalfTagId ); 
  
  // eslint-disable-next-line
  const [trainingStartTime, setTrainingStartTime] = useState(tStart.format(tFormat))

  const duplicateSlot = async slot => {
    if (!slot.scheduled) {
      setLoading(true)
      const _Template = await REQ(`/api/templates/${Template.id}/duplicate_slot/${slot.id}`, "POST")
      setLoading(false)
      mutate(url, _Template)
    }
  }

  const updateSlot = async args => {
    setLoading(true)
    const { field, value, id } = args
    //console.log("updateSlot", field, value)
    // const slot = context.slots.get(id)
    // const update = { ...Slot }
    const update = !field && !!value ? { ...value } : {}
    if (field) update[field] = value ? value : null
    
		if( field === "start" ) {
			var start = new Date(value);
    	var h = start.toTimeString().substring(0,5);
      update.time = h;
      update.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}

    /*
    if( field === "trainStartDate" ) {
			var trainStart = new Date(value);
    	var h = trainStart.toTimeString().substring(0,5);
      update.trainStart = h;
      update.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}
    */

    
    const _Template = await REQ(`/api/templates/${Template.id}/update_slot/${id}`, "PATCH", { ...update })
    setLoading(false)
    mutate(url, _Template)
  }

  const removeSlot = async slot => {
    if (!slot.scheduled) {
      if (!disabled) {
        setLoading(true)
        const _Template = await REQ(`/api/templates/${Template.id}/delete_slot/${slot.id}`, "DELETE")
        setLoading(false)
        mutate(url, _Template)
      }
    }
  }

  const clearDates = async slot => {
    if (!slot.scheduled) {
      setLoading(true)
      const _Template = await REQ(`/api/templates/${Template.id}/slot/${slot.id}/clear_dates`, "PATCH")
      setLoading(false)
      mutate(url, _Template)
    }
  }

  const disabled = props.disabled || Slot.scheduled
  const shortOpts = { handleChange: updateSlot, type: "short" }
  const defProps = { style: { margin: "3px", opacity: disabled ? ".2" : "1" }, theme: "filled", disabled }
  const delProps = { ...defProps, disabled, type: "close-circle", onClick: () => (!disabled ? removeSlot(Slot) : false) }
  const dupProps = { ...defProps, disabled, type: "copy", onClick: () => (!disabled ? duplicateSlot(Slot) : false) }
  const clearProps = { ...defProps, disabled, type: "minus-square", onClick: () => (!disabled ? clearDates(Slot) : false) }

  // useEffect(() => {
  //   console.log("Slot.js", Slot.datesToSchedule.length)
  // }, [Slot])

  if (Slot)
    return (
        <tr data-cy={`SlotRow`} className={loading ? "loading" : ""}>
          <td className="colEmployee">
            <div>
              <div style={{ position: "relative" }}>
                <InlineEdit
                  data-cy={`OperationalAssignment`}
                  disabled={Slot.scheduled}
                  value={Slot.operationalAssignment}
                  field="operationalAssignment"
                  id={Slot.id}
                  handleChange={updateSlot}
                  placeholder="Operational Assignment"
                />
              </div>
              <hr />
              <EmployeeSelect
                data-cy={`EmployeeSelect`}
                disabled={Slot.scheduled}
                employee={Slot.employee}
                allowClear={true}
                field="employeeNumber"
                id={Slot.id}
                handleChange={updateSlot}
              />
            </div>
          </td>
          <td className="colAccounting">
            <div>
            <AssignmentSelect
                data-cy={`AssignmengSelect`}
                disabled={Slot.scheduled}
                id={Slot.id}
                field="assignmentId" 
                value={Slot.assignmentId} 
                handleChange={updateSlot} 
              />
              <hr />
              <PayTypeSelect
                data-cy={`PayTypeSelect`}
                disabled={Slot.scheduled}
                id={Slot.id}
                field="payTypeCode" 
                value={Slot.payTypeCode} 
                handleChange={updateSlot} 
              />
            </div>
          </td>
          <td className="colAccounting">
            <div>
              <BusinessUnitSelect
                data-cy={`BusinessUnitSelect`}
                disabled={Slot.scheduled}
                field="businessUnitCode"
                value={Slot.businessUnitCode}
                id={Slot.id}
                handleChange={updateSlot}
                allowClear={true}
              />
              <hr />
              <SubLedgerSelect
                data-cy={`SubLedgerSelect`}
                disabled={Slot.scheduled}
                field="subLedgerCode"
                value={Slot.subLedgerCode}
                id={Slot.id}
                handleChange={updateSlot}
                allowClear={true}
              />
            </div>
          </td>
          {!Template.isOvertime && (
            <td className="colDates">
              {!Template.isOvertime  && (
                <div className={Slot.scheduled ? "disabled" : ""}>
                  <WeekdayPicker data-cy={`WeekdayPicker`} disabled={Slot.scheduled} record={Slot} handleChange={updateSlot} />
                </div>
              )}
            </td>
          )}
          <td className="colShortDays">
            {!!Template.isOvertime  && (
              <div>
                <DateSelector data-cy={`DatesToSchedule`} disabled={Slot.scheduled} slot={Slot} />
              </div>
            )}
            {!Template.isOvertime && (
              <div>
                <ShortDayFields
                  data-cy={`ShortDay1`}
                  {...shortOpts}
                  disabled={Slot.scheduled}
                  id={Slot.id}
                  value={Slot}
                  firstOrSecond="first"
                />
                <hr />
                <ShortDayFields
                  data-cy={`ShortDay2`}
                  {...shortOpts}
                  disabled={Slot.scheduled}
                  id={Slot.id}
                  value={Slot}
                  firstOrSecond="second"
                />
              </div>
            )}
            <hr />
            <div>
              <CheckBox
                data-cy={`PaidLunchCheckBox`}
                disabled={Slot.scheduled}
                field="paidLunch"
                value={!Slot.paidLunch}
                id={Slot.id}
                handleChange={e => {
                  updateSlot({ field: "paidLunch", value: !!Slot.paidLunch ? false : true, id: Slot.id })
                }}
              />
              <span>Unpaid Lunch</span>
            </div>
            { (hasTrainingGroup && <><hr />
            <div>
              <CheckBox
                data-cy={`IsTrainingCheckBox`}
                disabled={Slot.scheduled}
                field="isTraining"
                value={Slot.isTraining}
                id={Slot.id}
                handleChange={e => {
                  updateSlot({ field: "isTraining", value: !!Slot.isTraining ? false : true, id: Slot.id })
                }}
              />
              <span>Create Training Shifts</span>
            </div>
            {Slot.isTraining && <><hr />
            <div data-cy="ShiftStart" className="field inline_label">
              <label>
                Training Start Time
              </label>
              <StartTimeField
                data-cy="trainingStartTimeField"
                disabled={disabled}
                field="start"
                value={{ start: trainingStartTime, end: trainingStartTime }}
                //value={{ start: Slot.trainStartDate, end: Slot.trainStartDate }}
                //handleChange={updateTemplate}
                id={Slot.id}
                handleChange={e => {
                  updateSlot({ field: "trainStartDate", value: e.value, id: Slot.id })
                }}
              />
            </div></>}
            </>)}
          </td>
          <td className="colTime">
            <div>
              <StartTimeField
                data-cy={`StartTime`}
                disabled={Slot.scheduled}
                value={{ start: Slot.start, end: Slot.end }}
                field="start"
                id={Slot.id}
                handleChange={updateSlot}
              />
              <hr />
              <DurationSelect
                data-cy={`DurationSelect`}
                disabled={Slot.scheduled}
                value={Slot.duration}
                field="duration"
                id={Slot.id}
                handleChange={updateSlot}
              />
            </div>
          </td>
          <td className="colActions">
            <div className="slotActions">
              <Tooltip title="Delete Position & Shifts" placement="left">
                <Icon data-cy={`ActionDeleteSlot`} className="slotAction" {...delProps} />
              </Tooltip>
              <Tooltip title="Duplicate Position" placement="left">
                <Icon data-cy={`ActionDuplicateSlot`} className="slotAction" {...dupProps} />
              </Tooltip>
              <Tooltip title="Clear Dates" placement="left">
                <Icon data-cy={`ActionClearDates`} className="slotAction" {...clearProps} />
              </Tooltip>
            </div>
          </td>
        </tr>
    )
  else
    return (
      <tr>
        <td colSpan="5">{`FOR SOME REASON SLOT IS NOT DEFINED ... slotId: ${Slot.id} templateId: ${Slot.templateId}`}</td>
        <td colSpan="2">{Template.slots.map(s => s.id)}</td>
      </tr>
    )
}

export default RosterSlot
