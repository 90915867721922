// eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useState, useEffect } from "react"
import { reqOptions, _, emit, store, REQ } from "../store/psrs"
import useSWR, { mutate } from "swr"
import { useParams, useHistory } from "react-router-dom"
import { Formik, Form } from "formik"
import { Icon, Tag, Row, Col, Breadcrumb, Spin, Button, notification } from "antd"
import DateSelector from "./Template/DateSelector"
import { WeekdayPicker, BusinessUnitSelect, StartTimeField, DurationSelect, TagSelect, AssignmentSelect, PayTypeSelect } from "./Forms"
import { SupervisorSelect, SubLedgerSelect, PayPeriodRangeSelector, InlineEdit } from "./Forms"
import Roster from "./Template/Roster"
import "react-dates/lib/css/_datepicker.css"
import { Help } from "./Help"
//import moment from "moment"

export const Template = props => {
  const params = useParams()
  const { teamId, templateId } = params
  const history = useHistory()
  const id = templateId

  const fetcher = url => {
    const options = { ...reqOptions }
    return fetch(url, options)
      .then(res => {
        // setLoading(false)
        return res.json()
      })
      .catch(err => emit("logout", "error loading template"))
  }
  const key = `${store.get("api")}/api/templates/${id}`

  const { data } = useSWR(key, fetcher)

  const [creating, setCreating] = useState(false)
  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState( data || null )

  const [assignmentId, setAssignmentId] = useState(null)
  //const [payTypeCode, setPayTypeCode]  = useState("1")

  //const [datesToSchedule2, setDatesToSchedule2] = useState( useSWR(key, fetcher))
  const disabled = false

  const updateTemplate = async ({ field, value }) => {
    // const update = { ...template }
		if( field === "start" ) {
			//var td = moment(value);
      //value = td.subtract(value.getTimezoneOffset(), 'm').toISOString();
		}

    if (field === 'assignmentId') {
        setAssignmentId(value)
    }

    //if (field === 'payTypeCode') {
    //   setPayTypeCode(value)
   // }

    const update = { isValid: true, year:(new Date()).getTimezoneOffset() }
    if (value && value.firstWeekDays) {
      update.firstWeekDays = value.firstWeekDays
    }
    if (value && value.secondWeekDays) {
      update.secondWeekDays = value.secondWeekDays
    }
    if (field) update[field] = value || null
    if (field === "isOvertime" ) {
      update.datesToSchedule = []
      update.firstWeekDays = []
      update.secondWeekDays = []
    }

    const updated = await REQ(`/api/templates/${templateId}`, "PATCH", update)
    if (updated) mutate(key, updated)
    setTemplate(updated)

		if( field === "start" ) {
			var start = new Date(value);
			var slot = {
				time:start.toTimeString().substring(0,5),
				timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone
			}
			syncSlots(slot);
		}

    if( field === "dateSelector" ) {
			//var start = new Date(value);
			//var slot = {
			//	time:start.toTimeString().substring(0,5),
		  //	timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone
			//}
			//syncDates()
		}

  }

  const checkCommand = async () => {
    setTemplate(data)
    // if no supervisor2 make it supervisor1
    if (template && !!template.supervisor1 && !template.supervisor2) {
      const Supervisor1 = _.find(store.get("Supervisors"), { number: template.superNumber1 }) 
      const supervisor2 = { superNumber2: Supervisor1.number }
      const updated = await REQ(`/api/templates/${templateId}`, "PATCH", { ...supervisor2 })
      if (updated) mutate(key, updated)
      setTemplate(updated)
    }
    // if no supervisor3 make it supervisor2
    if (template && !!template.supervisor2 && !template.supervisor3) {
      const Supervisor2 = _.find(store.get("Supervisors"), { number: template.superNumber2 }) || template.supervisor1
      const supervisor3 = { superNumber3: Supervisor2.number }
      const updated = await REQ(`/api/templates/${templateId}`, "PATCH", { ...supervisor3 })
      if (updated) mutate(key, updated)
      setTemplate(updated)
    }
  }

  useEffect(() => {
    checkCommand()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  //-//useEffect(() => {
    // updateTemplate({ field: "teamId", value: teamId })
  //-//}, [])

  //useEffect(() => {
  //  console.log(' Dates Changed')
  //  setTemplate(template)
 // }, [template])

  const syncSlots = async update => {
		//console.log("SS:" + JSON.stringify(update));
    setLoading(true)
    const _template = await REQ(`/api/templates/${template.id}/sync_slots`, "PATCH", { ...update })
    //console.log("syncSlots", _template)
    setLoading(false)
    mutate(key, _template)

  }

  const syncAll = async () => {
    const { /*datesToSchedule,*/ firstWeekDays, secondWeekDays } = template
		//console.log(template.start);
    //console.log('datesToSchedule2 ', datesToSchedule2)


    const updatedTemplate = await REQ(`/api/templates/${id}`, "GET")

		var start = new Date(template.start);
		//console.log("TD: " + td.toISOString());
		var h = start.toTimeString().substring(0,5);
    syncSlots({
      datesToSchedule: updatedTemplate.datesToSchedule,
      firstWeekDays,
      secondWeekDays,
      businessUnitCode: template.businessUnitCode,
      subLedgerCode: template.subLedgerCode,
      start: start,
      trainStartDate: start,
			time: h,
			timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
      duration: template.duration,
      payTypeCode: template.payTypeCode,
      assignmentId: assignmentId,
    })
  }

  const PageHeader = ({ teamName, scheduleName }) => (
    <>
      <div className="view_title">
        <h1 data-cy="page-title">{scheduleName}</h1>
      </div>
      <div className="Breadcrumb">
        <Breadcrumb data-cy="breadcrumb">
          <Breadcrumb.Item data-cy="breadcrumb-1" key="home">
            <span onClick={() => history.push(`/nav`)}>
              <Icon type="home" />
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-cy="breadcrumb-2" key="teams">
            <span data-cy="back-back" onClick={() => history.push(`/teams/mine`)}>
              <Icon type="team" />
              <span> My Teams</span>
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-cy="breadcrumb-3" key="team">
            <span data-cy="back" onClick={() => history.push(`/team/${teamId}`)}>
              <span>{teamName || "loading"}</span>
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item data-cy="breadcrumb-last">{scheduleName}</Breadcrumb.Item>
        </Breadcrumb>
        <div className="actionRight">
          <Button icon="sync" onClick={syncAll} type="primary" ghost={true} size="small">
            Sync All
          </Button>
        </div>
      </div>
    </>
  )

  if (!data) {
    return (
      <div className="template">
        <PageHeader teamName="Loading" scheduleName="Loading" />
      </div>
    )
  }

  if (!!data && !!template) {
    const { superNumber1, superNumber2, payPeriodStartId, payPeriodEndId } = template
    const Slots = data.slots
    const hasSupervisors = superNumber1 && superNumber2 ? true : false
    const slotsWithoutDates = template.slots ? Slots.filter(s => s.datesToSchedule.length === 0) : []
    const slotsHaveDates = !template.isOvertime ? true : slotsWithoutDates.length === 0
    const hasDateRange = payPeriodStartId && payPeriodEndId ? true : false
    const hasDatesToSchedule = (template.datesToSchedule && template.datesToSchedule.length) || slotsHaveDates ? true : false
    const hasDates = hasDateRange || hasDatesToSchedule
    const completeSchedule = hasSupervisors && hasDates

    const validateForm = values => {
      //console.log("validateForm")
      let errors = {}
      const isValid = Object.keys(errors).length > 0 ? false : true
      values.isValid = isValid
      updateTemplate({ value: values })
      return errors
    }

    const createShifts = async () => {
      setCreating(true)
      const shifts = await REQ(`/api/templates/${template.id}/create_shifts`, "POST")
      localStorage.setItem("time", shifts.time)
      setCreating(false)
      notification.open({
        message: "Shift Creation Complete",
        description: `${shifts.created} Shifts were created. (${shifts.time} SECONDS)`,
        placement: "bottomRight",
        duration: 30,
      })
      // if (shifts.created) handleChange({ value: shifts.template })
    }

    const formikOptions = {
      initialValues: template,
      enableReinitialize: true,
      validateOnChange: true,
      validateOnBlur: true,
      validate: validateForm,
    }

    const superSelect = {
      disabled: disabled,
      handleChange: updateTemplate,
      simple: true,
      id,
    }

    const syncDates = async () => {
      const updatedTemplate = await REQ(`/api/templates/${template.id}`, "GET")
      //const { datesToSchedule } = template
      const { datesToSchedule } = updatedTemplate     
      syncSlots({ datesToSchedule })
    }

    const syncDays = () => {
      const { firstWeekDays, secondWeekDays } = template
      syncSlots({ firstWeekDays, secondWeekDays })
    }

    const syncBusinessUnit = () => {
      syncSlots({ businessUnitCode: template.businessUnitCode })
    }

    const syncSubLedger = () => {
      syncSlots({ subLedgerCode: template.subLedgerCode })
    }

    const syncActingRole = () => {
      syncSlots({ assignmentId: assignmentId })
    }

    const syncPayTypeCode = () => {
      syncSlots({ payTypeCode: template.payTypeCode })
    }

    const syncStartTime = () => {
      syncSlots({ start: template.start })
      syncSlots({trainStartDate: template.start })
    }

    const syncDuration = () => {
      syncSlots({ duration: template.duration })
    }

    return (
      <div className="Template">
        <PageHeader scheduleName={template.name} teamName={template.team ? template.team.name : "TEAM"} />
        <div className="view_content">
          <Spin data-cy="spin-loader" wrapperClassName="loading" spinning={creating} tip="Creating Shifts ..." size="large">
            {disabled && (
              <div className="disabledtemplate">This template has been scheduled and is now locked. To unlock ... </div>
            )}
            <Formik {...formikOptions}>
              {bag => {
                return (
                  <React.Fragment>
                    <div className="view_title" style={{ display: "none" }}>
                      <h1 data-cy="view-title">Schedule Templates</h1>
                      <div className="owner_select" />
                    </div>
                    <Form className={`template ${disabled && "disabled"}`}>
                      <div className="grid_container">
                        <section className="columnA grid_section">
                          <Spin spinning={loading}>
                            <div data-cy="ScheduleName" className="field">
                              <label>Schedule Name</label>
                              <InlineEdit
                                data-cy="templateNameInput"
                                id="template-name"
                                disabled={disabled}
                                field="name"
                                value={template.name || "Unnamed template"}
                                handleChange={updateTemplate}
                              />
                            </div>
                            <div className="field">
                              <label>Select Groups</label>
                              <TagSelect
                                model="template"
                                field="tagIds"
                                value={template.tags}
                                record={template}
                                handleChange={updateTemplate}
                              />
                            </div>
                            <div data-cy="SubLedger" className="field">
                              <label>
                                <Help
                                  data-cy="sync-paytypecode"
                                  type='sync'
                                  text="Sync Pay Type Code"
                                  handleClick={syncPayTypeCode}
                                />
                                Pay Type Code
                              </label>
                              <PayTypeSelect
                                data-cy={`PayTypeSelect`}
                                disabled={disabled}
                                //id={Slot.id}
                                field="payTypeCode" 
                                record={template}
                                //value={payTypeCode} 
                                handleChange={updateTemplate} 
                              />
                            </div>
                          </Spin>
                        </section>
                        <section className="columnB grid_section">
                          <Spin spinning={loading}>
                            <div data-cy="BusinessUnit" className="field">
                              <label>
                                <Help
                                  data-cy="sync-businessunit"
                                  type="sync"
                                  text="Sync Roster"
                                  handleClick={syncBusinessUnit}
                                  className="sync"
                                />
                                Override Business Unit
                              </label>
                              <BusinessUnitSelect
                                data-cy="templateBusinessUnitSelect"
                                allowClear={true}
                                disabled={disabled}
                                field="businessUnitCode"
                                record={template}
                                handleChange={updateTemplate}
                              />
                            </div>
                            <div data-cy="SubLedger" className="field">
                              <label>
                                <Help
                                  data-cy="sync-subledger"
                                  type="sync"
                                  text="Sync Roster"
                                  handleClick={syncSubLedger}
                                  className="sync"
                                />
                                Sub-Ledger
                              </label>
                              <SubLedgerSelect
                                data-cy="templateSubLedgerSelect"
                                disabled={disabled}
                                field="subLedgerCode"
                                record={template}
                                handleChange={updateTemplate}
                              />
                            </div>
                            <div data-cy="SubLedger" className="field">
                              <label>
                                <Help
                                  data-cy="sync-actingrole"
                                  type='sync'
                                  text="Sync Acting Role"
                                  handleClick={syncActingRole}
                                />
                                Acting Role
                              </label>
                              <AssignmentSelect
                                data-cy={`AssignmengSelect`}
                                disabled={disabled}
                                //id={Slot.id}
                                field="assignmentId" 
                                //record={template}
                                value={assignmentId} 
                                handleChange={updateTemplate} 
                              />
                            </div>
                          </Spin>
                        </section>
                        <section className="columnC grid_section">
                          <Spin spinning={loading}>
                            <div data-cy="Supervisor">
                              <label>Supervisors</label>
                              <div className="field inline_label">
                                <label>1</label>
                                <SupervisorSelect
                                  {...superSelect}
                                  data-cy="templateSupervisor1Select"
                                  supervisor={template.supervisor1}
                                  field="superNumber1"
                                  record={template}
                                />
                              </div>
                              <div className="field inline_label">
                                <label>2</label>
                                <SupervisorSelect
                                  {...superSelect}
                                  data-cy="templateSupervisor2Select"
                                  field="superNumber2"
                                  supervisor={template.supervisor2}
                                  record={template}
                                />
                              </div>
                              <div className="field inline_label">
                                <label>3</label>
                                <SupervisorSelect
                                  {...superSelect}
                                  data-cy="templateSupervisor3Select"
                                  supervisor={template.supervisor3}
                                  field="superNumber3"
                                  record={template}
                                />
                              </div>
                            </div>
                          </Spin>
                        </section>
                        <section className="columnD grid_section">
                          <Spin spinning={loading}>
                            <Row gutter={8}>
                              <Col className="startTime" span={12}>
                                <div data-cy="ShiftStart" className="field inline_label">
                                  <label>
                                    <Help data-cy="sync-start-time" type="sync" text="Sync Roster" handleClick={syncStartTime} />
                                    Start Time
                                  </label>
                                  <StartTimeField
                                    data-cy="templateStartTimeField"
                                    disabled={disabled}
                                    field="start"
                                    value={{ start: template.start, end: template.end }}
                                    handleChange={updateTemplate}
                                    id={template.id}
                                  />
                                </div>
                              </Col>
                              <Col className="shiftDuration" span={12}>
                                <div data-cy="ShiftDuration" className="field inline_label">
                                  <label>
                                    <Help data-cy="sync-duration" type="sync" text="Sync Roster" handleClick={syncDuration} />
                                    Shift Duration
                                  </label>
                                  <DurationSelect
                                    data-cy="templateDurationSelect"
                                    field="duration"
                                    value={template.duration}
                                    handleChange={updateTemplate}
                                    id={template.id}
                                    disabled={disabled}
                                  />
                                </div>
                              </Col>
                              {(!template.isOvertime ) && (
                                <div data-cy="WeekDays" className="field item defaultWeekDays">
                                  <label>
                                    <Help data-cy="sync-weekdays" type="sync" text="Sync Roster" handleClick={syncDays} />
                                    Week Days
                                  </label>
                                  <WeekdayPicker
                                    data-cy="templateWeekDayPicker"
                                    labels={true}
                                    record={template}
                                    disabled={disabled}
                                    handleChange={updateTemplate}
                                  />
                                </div>
                              )}
                            </Row>
                            <Row gutter={8}>
                              {(!template.isOvertime) && (
                                <Col span={24}>
                                  <div data-cy="PayPeriodRange">
                                    <PayPeriodRangeSelector
                                      data-cy="templatePayPeriodRangeSelector"
                                      disabled={disabled}
                                      value={{ payPeriodStartId, payPeriodEndId }}
                                      record={template}
                                      handleChange={updateTemplate}
                                    />
                                  </div>
                                </Col>
                              )}
                              {(template.isOvertime) && (
                                <Col span={24}>
                                  <div data-cy="OvertimeDates">
                                    <h4>
                                      <Help data-cy="sync-dates" type="sync" text="Sync Roster" handleClick={syncDates} />
                                      Select Dates to Schedule
                                    </h4>
                                    <DateSelector 
                                      field='dateSelector' 
                                      data-cy="templateDateSelector" 
                                      template={template} 
                                    />
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Spin>
                        </section>
                        <Roster loading={loading} disabled={disabled} template={template} setTemplate={setTemplate} mutate={mutate} url={key} />
                      </div>

                      <footer>
                        <div className="datesToSchedule">
                          <h4 style={{ marginRight: "1rem", display: "inline-block" }}>PayPeriods To Schedule:</h4>
                          <Tag>...</Tag>
                        </div>
                        <Button
                          data-cy="templateScheduleShiftsButton"
                          disabled={!completeSchedule ? true : disabled}
                          onClick={createShifts}
                        >
                          Schedule Shifts
                        </Button>
                      </footer>
                    </Form>
                  </React.Fragment>
                )
              }}
            </Formik>
          </Spin>
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}

export default Template
// export default clearRender(templateProfile)
